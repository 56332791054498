<template>
  <Modal ref="modal">
    <div class="sn-details">
      <h1>{{ $t('sn.created_successfully') }}</h1>
      <small>
        <i18n path="sn.you_will_be_redirected">
          <a :href="sn.link">{{ sn.linkWithoutProtocol }}</a>
        </i18n>
      </small>

      <img
        :src="sn.avatar"
        class="sn-avatar"
      >

      <div class="username">
        @{{ sn.username }}
      </div>
      <div class="row">
        <div>{{ sn.name }}</div>
        /
        <div>{{ sn.nameRu }}</div>
      </div>
      <div>
        {{ sn.description }}
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';

import Sn from '@/models/Sn';

export default {
  components: {
    Modal,
  },
  props: {
    sn: {
      type: Sn,
      default: () => new Sn({}),
    },
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
  },
};
</script>

<style scoped lang="scss">
.sn-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;

  .sn-avatar {
    width: 64px;
    height: 64px;
    border-radius: 8px;
  }

  .username {
    color: $clr-text-trietary;
  }

  a {
    color: #007BFF;
  }

  .row {
    display: flex;
    gap: 8px;
    justify-content: center;
    font: $font-sbw-400;
  }
}
</style>
