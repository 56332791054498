<template>
  <form @submit.prevent>
    <label class="label">
      {{ $t('sn.founder_email') }}
      <input
        v-model="email"
        class="text-input"
        type="text"
      >
    </label>

    <button
      v-if="!sent"
      :disabled="$v.$invalid || emailError"
      class="btn submit"
      @click="sendEmail"
    >
      {{ $t('sn.send_verification_email') }}
    </button>
    <button
      v-else
      disabled
      class="btn submit secondary"
    >
      {{ $t('sn.email_sent') }}
    </button>

    <div
      v-if="emailError"
      class="error-message"
    >
      {{ $t('sn.email_rate_limit', {seconds: rateLimitTimer}) }}
    </div>

    <div
      v-if="sent"
      style="display: contents;"
    >
      <label class="label">
        {{ $t('sn.email_code') }}
        <input
          v-model="code"
          class="text-input"
          type="text"
        >
      </label>

      <button
        :disabled="!code.length"
        class="btn submit"
        @click="sendCode"
      >
        {{ $t('sn.send_code') }}
      </button>

      <div
        v-if="codeError"
        class="error-message"
      >
        {{ $t(codeError) }}
      </div>
    </div>
  </form>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { mapGetters, mapMutations } from 'vuex';

import api from '@/api';

export default {
  data() {
    return {
      email: null,
      sent: false,
      emailError: false,
      rateLimitInterval: null,
      rateLimitTimer: 0,
      code: '',
      codeError: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
    }),
  },
  mounted() {
    this.email = this.profile.email;
  },
  methods: {
    ...mapMutations({
      updateProfile: 'updateProfile',
    }),
    async sendEmail() {
      const response = await api.user.sendEmailCodeSn({ email: this.email });

      if (response.code) {
        this.emailError = true;
        this.rateLimitTimer = 60;
        this.rateLimitInterval = setInterval(() => {
          this.rateLimitTimer -= 1;
          if (this.rateLimitTimer <= 0) {
            clearInterval(this.rateLimitInterval);
            this.rateLimitInterval = null;
            this.emailError = false;
          }
        }, 1000);
      } else {
        this.sent = true;
      }
    },
    async sendCode() {
      const response = await api.user.emailVerifySn({ email: this.email, code: this.code });
      if (response.code) {
        switch (response.code) {
          case 17:
            this.codeError = 'sn.code_rate_limit';
            break;
          case 19:
            this.codeError = 'sn.code_invalid';
            break;
          default:
            this.codeError = true;
            break;
        }
      } else {
        this.updateProfile({ is_email_verified: true, email: this.email });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './form';
</style>
