<template>
  <div>
    <div
      v-if="emailWasVerified"
      class="register-sn"
    >
      <SnCreationForm />
    </div>
    <div
      v-else
      class="register-sn-paged"
    >
      <div v-if="!profile.emailVerified">
        <h1>{{ $t('sn.first_step') }}</h1>
        <EmailVerification />
      </div>
      <div v-else>
        <h1>{{ $t('sn.second_step') }}</h1>
        <SnCreationForm />
      </div>

      <div class="dots">
        <div
          class="dot"
          :class="{ 'active': !profile.emailVerified }"
        >
          1
        </div>
        <div
          class="dot"
          :class="{ 'active': profile.emailVerified }"
        >
          2
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import EmailVerification from './EmailVerification.vue';
import SnCreationForm from './SnCreationForm.vue';

export default {
  name: 'RegisterSn',
  components: {
    EmailVerification,
    SnCreationForm,
  },
  data() {
    return {
      emailWasVerified: null,
    };
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
    }),
  },
  mounted() {
    this.emailWasVerified = this.profile.emailVerified;
  },
};
</script>

<style lang="scss" scoped>
$form-width: 661px;

.register-sn,
.register-sn-paged {
  position: relative;
  max-width: $form-width;
  border-radius: 16px;
  background: white;
  margin: auto;
  margin-bottom: 128px;
  padding: 73px 130px;
  box-sizing: border-box;
}

.register-sn-paged {
  padding: 92px 130px 42px;
}

h1 {
  font-weight: 400;
  position: absolute;
  top: 32px;
  left: 32px;
  color: $clr-text-secondary;
}

.dots {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 64px;

  .dot {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: $clr-20;
    font: $font-rn-200;
    text-align: center;

    &.active {
      background: #007BFF;
      color: white;
    }
  }
}

@media screen and (max-width: $form-width) {
  .register-sn {
    padding: 8px;
  }
}
</style>
