<template>
  <form @submit.prevent>
    <label class="label">
      {{ $t('sn.founder_name') }}
      <input
        class="text-input"
        type="text"
        disabled
        :value="profile.name"
      >
    </label>

    <label class="label">
      {{ $t('sn.founder_email') }}
      <input
        class="text-input"
        :class="{ 'error': $v.profile.emailVerified.$error }"
        type="text"
        disabled
        :value="profile.email"
      >
      <div
        v-if="$v.profile.emailVerified.$error"
        class="error-message"
      >
        {{ $t('sn.founder_email_verified_error') }}
      </div>
    </label>

    <label class="label">
      {{ $t('sn.founder_phone') }}
      <input
        class="text-input"
        type="text"
        disabled
        :value="profile.phone"
      >
    </label>

    <label class="label">
      {{ $t('sn.username') }}
      <input
        v-model="$v.sn.username.$model"
        class="text-input"
        type="text"
      >
      <div
        v-if="$v.sn.username.$error"
        class="error-message"
      >
        {{ $t('sn.username_error') }}
      </div>
    </label>

    <label class="label">
      {{ $t('sn.new_name_ru') }}
      <input
        v-model="sn.nameRu"
        class="text-input"
        type="text"
      >
    </label>

    <label class="label">
      {{ $t('sn.new_name') }}
      <input
        v-model="sn.name"
        class="text-input"
        type="text"
      >
    </label>

    <label class="label">
      {{ $t('sn.description') }}
      <textarea
        v-model="sn.description"
        class="text-input"
        type="text"
      />
    </label>

    <!-- <label class="label">
        {{ $t('sn.arround_subdomain') }}
        <input
          v-model="sn.subdomain"
          class="text-input"
          type="text"
        >
      </label> -->

    <div class="label avatar-label">
      <img
        v-if="sn.avatar"
        :src="avatarPreview"
        class="avatar"
      >
      <PhotoIcon
        v-else
        class="avatar-icon"
      />
      <label class="btn secondary">
        {{ $t('select') }}

        <input
          class="avatar-upload"
          type="file"
          @change="selectAvatar"
        >
      </label>
    </div>

    <div class="buttons">
      <button
        :disabled="$v.$invalid"
        class="btn submit"
        @click="submit"
      >
        {{ $t('sn.create') }}
      </button>

      <button
        class="btn secondary"
        @click="cancel"
      >
        {{ $t('cancel') }}
      </button>
    </div>

    <SuccessModal
      ref="successModal"
      :sn="createdSn"
    />
  </form>
</template>

<script>
import {
  required, minLength, maxLength,
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import api from '@/api';
import customValidators from '@/utils/validators';

import PhotoIcon from '@/components/icons/Photo.vue';

import SuccessModal from './SuccessModal.vue';

import snFromBackend from '@/models/dto/snFromBackend';
import Sn from '@/models/Sn';

export default {
  components: {
    PhotoIcon,
    SuccessModal,
  },
  data() {
    return {
      sn: new Sn(),
      error: false,
      createdSn: undefined,
    };
  },
  validations: {
    profile: {
      emailVerified: {
        truthy: (value) => value,
      },
    },
    sn: {
      username: {
        required,
        username: customValidators.username,
        minLength: minLength(3),
        maxLength: maxLength(20),
      },
      name: {
        required,
      },
      nameRu: {
        required,
      },
      description: {
        required,
      },
      avatar: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(['profile']),
    avatarPreview() {
      return URL.createObjectURL(this.sn.avatar);
    },
  },
  mounted() {
    this.$v.profile.$touch();
  },
  methods: {
    selectAvatar(event) {
      const file = event.target.files[0];
      this.sn.avatar = file;
    },
    async submit() {
      const response = await api.sn.registerSn(this.sn);

      if (response.code) {
        this.error = true;
      } else {
        this.createdSn = snFromBackend(response);
        this.$refs.successModal.open();
        setTimeout(() => {
          window.location.href = this.createdSn.link;
        }, 5000);
      }
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './form';

.avatar-label {
  display: flex;
  gap: 16px;

  .avatar, .avatar-icon {
    border-radius: 4px;
    width: 96px;
    height: 96px;
  }

  .avatar-icon {
    padding: 36px;
    width: 24px;
    height: 24px;
    background: #F0F2F5;
  }

  .avatar-upload {
    display: none;
  }
}
</style>
